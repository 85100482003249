import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../../components/layout'
import { Col, Container, Row } from 'reactstrap'

const IndexPage = () => (
  <Layout>
    <Container fluid>
      <Helmet title="the synth formerly known as instrument designer">
        <meta httpEquiv="refresh" content="0; url=/id700" />
      </Helmet>
      <Row>
        <Col>redirecting</Col>
      </Row>
    </Container>
  </Layout>
)

export default IndexPage
